
import { defineComponent } from "vue";
import { getAnalytics, logEvent } from "firebase/analytics";

export default defineComponent({
  name: "InstallButton",
  components: {},
  data() {
    return {
      downloadLink:
        "https://firebasestorage.googleapis.com/v0/b/typerunner.appspot.com/o/distribution%2FTypeRunnerInstaller%201.1.1.dmg?alt=media&token=1826e20a-509b-444e-b006-c6a9f3af1f2b",
      buttonTitle: "最新版ダウンロード",
    };
  },
  methods: {
    didTapDownloadLink() {
      let analytics = getAnalytics();
      logEvent(analytics, "didTapDownloadLink", {
        date: Date.now(),
      });
    },
  },
});
